<template>
	<div class="vld-parent">
	<loading :active="loading" color="#D9F7C5" loader="bars"/>
	</div>	
	<div class="p-grid crud-demo">
		<div class="p-col-12">
			<div class="card" id="xyz">
				<h4 class="p-m-2">Notificaciones</h4>			
				<Toast/>

		<OverlayPanel ref="comodines">
			<div >
				<div>
					<span style="font-weight: bold;">Comodines disponibles para personalización de la plantilla:</span>
				</div>
				<div v-if="appMode==0">
					<dl>
						<dt class="p-mb-1"/>
							<dd><Tag severity="info" class="p-mb-1" value="%TIPODOCUMENTO%"/></dd>
							<dd><Tag severity="info" class="p-mb-1" value="%FECHA%"/></dd>
							<dd><Tag severity="info" class="p-mb-1" value="%REFERENCIA%"/></dd>
							<dd><Tag severity="info" class="p-mb-1" value="%CODIGO%"/></dd>
							<dd><Tag severity="info" class="p-mb-1" value="%CLIENTE%"/></dd>																		
							<dd><Tag severity="info" class="p-mb-1" value="%EMPRESA%"/></dd>
							<dd><Tag severity="info" class="p-mb-1" value="%CONTRATO%"/></dd>
							<dd><Tag severity="info" class="p-mb-1" value="%CONTRATO_VIGENCIA_INICIO%"/></dd>
							<dd><Tag severity="info" class="p-mb-1" value="%CONTRATO_VIGENCIA_FIN%"/></dd>
							<dd><Tag severity="info" class="p-mb-1" value="%FECHA_PAGO%"/></dd>
							<dd><Tag severity="info" class="p-mb-1" value="%SUBTOTAL%"/></dd>
							<dd><Tag severity="info" class="p-mb-1" value="%DESCUENTO%"/></dd>
							<dd><Tag severity="info" class="p-mb-1" value="%TOTAL%"/></dd>	
					</dl>	
				</div>
				<div v-else>
					<dl>	
						<dt class="p-mb-1"/>							
							<dd><Tag style="background: green" rounded class="p-mb-1" value="%TIPODOCUMENTO%"/></dd>
							<dd><Tag style="background: green;"  rounded class="p-mb-1" value="%FECHA%"/></dd>
							<dd><Tag style="background: green;"  rounded class="p-mb-1" value="%REFERENCIA%"/></dd>
							<dd><Tag style="background: green;" rounded class="p-mb-1" value="%CODIGO%"/></dd>
							<dd><Tag style="background: green;"  rounded class="p-mb-1" value="%CLIENTE%"/></dd>																		
							<dd><Tag style="background: green;" rounded class="p-mb-1" value="%FECHA_PAGO%"/></dd>
							<dd><Tag style="background: green;" rounded class="p-mb-1" value="%SUBTOTAL%"/></dd>
							<dd><Tag style="background: green;" rounded class="p-mb-1" value="%DESCUENTO%"/></dd>
							<dd><Tag style="background: green;" rounded class="p-mb-1" value="%TOTAL%"/></dd>	
							<dd><Tag style="background: green;"  rounded class="p-mb-1" value="%COMPLEJO%"/></dd>
							<dd><Tag style="background: green;"  rounded class="p-mb-1" value="%INMUEBLE%"/></dd>
							<dd><Tag style="background: green;"  rounded class="p-mb-1" value="%CATEGORIA%"/></dd>
							<dd><Tag style="background: green;"  rounded class="p-mb-1" value="%AREA%"/></dd>
							<dd><Tag style="background: green;"  rounded class="p-mb-1" value="%PROPIETARIO%"/></dd>	
							<dd><Tag style="background: green;"  rounded class="p-mb-1" value="%OCUPANTE%"/></dd>							
							<dd><Tag style="background: darkcyan;" rounded class="p-mb-1" value="%RECURSO%"/></dd>
							<dd><Tag style="background: darkcyan;" rounded class="p-mb-1" value="%DESDE%"/></dd>
							<dd><Tag style="background: darkcyan;" rounded class="p-mb-1" value="%HASTA%"/></dd>
							<dd><Tag style="background: darkcyan;" rounded class="p-mb-1" value="%ESTADO%"/></dd>
							<dd><Tag style="background: darkcyan;" rounded class="p-mb-1" value="%PROPIETARIO%"/></dd>	
							<dd><Tag style="background: darkcyan;" rounded class="p-mb-1" value="%COMENTARIOS%"/></dd>								
					</dl>					
				</div>
			</div>
		</OverlayPanel>

		<TabView :activeIndex="activeTabIndex" >
			<TabPanel>
				<template #header>
					<i class="pi pi-envelope p-mr-2"></i>
					<span>Cuenta para Envío de Notificaciones</span>
				</template>
                <div class="p-fluid p-formgrid p-grid">
                        <div class="p-field p-col-12">
							<label class="p-mr-1 p-as-center" for="activo">Activo</label>
							<Checkbox v-model="perfil.activo" :binary="true" />
						</div>    
						<div class="p-field p-col-12">
							<label class="p-mr-1 p-as-center" style="font-weight: bold;" for="remitente">Remitente</label><span style="color: red;">*</span>
							<InputText id="remitente" v-model="perfil.remitente" :maxlength="100" required="true" autofocus />
						</div>      
						<div class="p-field p-col-12">
							<label class="p-mr-1 p-as-center" style="font-weight: bold;" for="remitente_email">Remitente Email</label><span style="color: red;">*</span>
							<InputText id="remitente_email" v-model="perfil.remitente_email" :maxlength="100" required="true" :class="{'p-invalid': v$.perfil.remitente_email.$invalid}"/>
							<small class="p-invalid" style="color: red"  v-if="v$.perfil.remitente_email.$invalid">El email del remitente no es valido!</small>
						</div>      
                        <div class="p-field p-col-12">
							<label class="p-mr-1 p-as-center" style="font-weight: bold;" for="remitente_email">bcc</label>
							<InputText id="bcc" v-model="perfil.bcc" :maxlength="100" :class="{'p-invalid': v$.perfil.bcc.$invalid}"/>
							<small class="p-invalid" style="color: red"  v-if="v$.perfil.bcc.$invalid">El email copia oculta no es valido!</small>
						</div>                              
                        <div class="p-field p-col-6">
							<label class="p-mr-1 p-as-center" style="font-weight: bold;" for="servidor">Servidor</label><span style="color: red;">*</span>
                            <InputText id="servidor" v-model="perfil.servidor" :maxlength="100" required="true"/>							
						</div>       
						<div class="p-field p-col-6">
							<label class="p-mr-1 p-as-center" style="font-weight: bold;" for="moneda">Puerto</label><span style="color: red;">*</span>
                            <InputNumber v-model="perfil.puerto" :min="0" :max="65535" />							
						</div>   
						<div class="p-field p-col-6">
							<label class="p-mr-1 p-as-center" style="font-weight: bold;" for="Usuario">Usuario</label><span style="color: red;">*</span>
							<InputText id="usuario" v-model="perfil.usuario" :maxlength="50" required="true"/>
						</div>                       
						<div class="p-field p-col-6">
							<label class="p-mr-1 p-as-center" for="contrasena">Contraseña</label>
							<Password v-model="perfil.contrasena" :feedback="false" toggleMask/>
						</div>
						<div class="p-field p-col-6">
							<label class="p-mr-1 p-as-center" for="Usuario">Usar SSL</label>
							<Checkbox v-model="perfil.ssl" :binary="true" />
						</div>                       
						<div class="p-field p-col-6">
							<label class="p-mr-1 p-as-center" for="contrasena">Requiere Autenticación</label>
							<Checkbox v-model="perfil.requiere_autenticacion" :binary="true" />
						</div>                          
					</div>   
                    <Button label="Actualizar" @click="actualizarPerfil" icon="pi pi-check" :disabled="!puedeActualizar"/>
			</TabPanel>
			<TabPanel>
				<template #header>
					<i class="pi pi-file-edit" />
					<span>Plantillas de Notificación</span>
				</template>	
				 <Toolbar class="p-mb-4">
					<template v-slot:left>
						<span class="p-input-icon-left">
							<i class="pi pi-search" />
							<InputText v-model="filters['global'].value" placeholder="Buscar..." class="p-mr-1"/>
						</span>		                            																	
					</template>
					<template v-slot:right>
						<Button label="Nuevo" icon="pi pi-plus" class="p-button-success p-mr-2" @click="openNew" />
					</template>
				</Toolbar>
				<DataTable :value="plantillas" dataKey="id_notificacion_plantilla" :paginator="true" :rows="10" :globalFilterFields="['plantilla']" :filters="filters"
							paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
							currentPageReportTemplate="Mostrando {first} de {last} de {totalRecords}"  :loading="loading">
							<Column field="plantilla" header="Plantilla"/>								
							<Column field="id_notificacion_plantilla">
							<template #body="slotProps">
								<div>
                                	<Button icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-1" @click="edit(slotProps.data)" />
                                	<Button icon="pi pi-trash" class="p-button-rounded p-button-warning p-mr-1" @click="confirmDelete(slotProps.data)" />
								</div>
							</template>			
						</Column>		
					</DataTable>
				</TabPanel>
			</TabView>
			</div>

			<Dialog v-model:visible="deleteDialog" :style="{width: '450px'}" header="Confirmar" :modal="true">
				<div class="confirmation-content">
				<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
				<span v-if="plantillaSel">Esta seguro que desea eliminar la plantilla seleccionada&nbsp;<b>[{{ plantillaSel.plantilla }}]</b>?</span>
				</div>
				<template #footer>
					<Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteDialog = false"/>
					<Button label="Sí" icon="pi pi-check" class="p-button-text" @click="deleteRec" />
				</template>
			</Dialog> 

			<Dialog v-model:visible="editDialog" :style="{width: '450px'}" header="Plantilla" :modal="true">
				<template #header>
					<div class="p-d-flex">
						<div style="font-weight: bold;" class="p-mr-1">Plantilla</div>				
					</div>
				</template>					
				<div class="confirmation-content">
                    <div class="p-fluid p-formgrid p-grid">
                        <div class="p-field p-col-12">
							<label class="p-mr-1 p-as-center" for="plantilla" style="font-weight: bold;">Plantilla</label><span style="color: red;">*</span>
							<InputText id="plantilla" v-model="plantillaSel.plantilla" :maxlength="100" required="true" autofocus />
						</div>    
						<div class="p-field p-col-12">
							<label class="p-mr-1 p-as-center" for="subject" style="font-weight: bold;">Asunto (Subject)</label><span style="color: red;">*</span>
							<InputText id="subject" v-model="plantillaSel.subject" :maxlength="250"/>
						</div>      
						<div class="p-field p-col-12">
							<label class="p-mr-1 p-as-center" for="body" style="font-weight: bold;">Cuerpo (Body)</label><span style="color: red;">*</span>
                            <Textarea id="body" v-model="plantillaSel.body" rows="7" cols="45" />
						</div>                            
                        <div class="p-field p-col-12">
							<label class="p-mr-1 p-as-center" for="adjunto" style="font-weight: bold;">Adjunto (Attachment)</label><span style="color: red;">*</span>
                            <InputText id="servidor" v-model="plantillaSel.adjunto_nombre" :maxlength="100"/>							
						</div>                       
					</div>  
                </div>
				<div class="p-d-flex p-jc-start">
					<Button type="button" icon="pi pi-percentage" class="p-ml-15 p-button-rounded" @click="toggle" />
				</div>	
				<template #footer>
					<Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="editDialog = false"/>
					<Button label="Guardar" icon="pi pi-check" class="p-button-text" @click="guardarRec" :disabled="!puedeActualizarPlantilla"/>
				</template>
			</Dialog>                  
		</div>
	</div>

</template>

<script>
import Loading from 'vue-loading-overlay';
import GrlService from '../service/grl.service';
import InvService from '../service/inv.service';
import {FilterMatchMode} from 'primevue/api';
import Tag from 'primevue/tag';
import { useVuelidate } from '@vuelidate/core';
import { required, email } from '@vuelidate/validators';

import { CURRENT_APP_MODE, MKT_APP_MODE } from '../service/constants';

export default {
	setup() {
		return { v$: useVuelidate() }
	},	
	components: {
		Loading, Tag
	},
	data() {
		return {
			appMode: CURRENT_APP_MODE,
			activeTabIndex: 0,
			loading: false,
			filters: {'global': {value: null, matchMode: FilterMatchMode.CONTAINS}},
            perfil: {
                id_notificacion_perfil: null,
                id_empresa: null,
                perfil: null,
                remitente: null,
                remitente_email: null,
                servidor: null,
                puerto: 587,
                ssl: true,
                requiere_autenticacion: false,
                usuario: null,
                contrasena: null,
                activo: false,
                bcc: null                
            },
            plantillas: [],
            plantillaSel: {
				id_notificacion_plantilla : "",
				plantilla: "",
				id_empresa: "",
				subject: "",
				body: "",
				adjunto_nombre: "",
				formato: "plain/text"
			},
			submitted: false,
            deleteDialog: false,
            editDialog: false,
			procesandoDlg: false,			
		}
	},
	validations() {
		return {
			perfil: { 
				remitente: { required },		
				remitente_email: { required, email },
				bcc: { email },
				servidor: { required },
				puerto: { required },
				usuario: { required }
			}, 
            plantillaSel: {
				plantilla: { required },	
				subject: { required },
				body: { required },
				adjunto_nombre: { required }
			}
		}
	},			
    computed: {
        puedeActualizar() {
            return !this.v$.perfil.$invalid;
        },
		puedeActualizarPlantilla () {
			return !this.v$.plantillaSel.$invalid;
		}
    },
	mounted() {
		this.cargarInfo();
	},
	methods: {
		async cargarInfo(){
			this.loading = true;
            try {
                var data = await GrlService.GetPerfilNotificacion(this.$store.state.auth.currentAppCtx);

                if(data)
                {
                    this.perfil = data[0];
                }

                data = await InvService.getPlantillasNotificacion(this.$store.state.auth.currentAppCtx);
                this.plantillas = data;

                this.loading = false;	
            } catch (err) {
				this.loading = false;
				this.$toast.add({severity:'error', summary: 'Error', detail: err, life: 3000})		                
            }
		},
        async actualizarPerfil() {
			this.loading = true;
            try {
                var data = await GrlService.SetPerfilNotificacion(this.$store.state.auth.currentAppCtx, this.perfil);
                this.loading = false;	
            } catch (err) {
				this.loading = false;
				this.$toast.add({severity:'error', summary: 'Error', detail: err, life: 3000})		                
            }
        },
		openNew() {
            var newRec = {
                id_notificacion_plantilla: "",
                plantilla: "",
                id_empresa: this.$store.state.auth.currentAppCtx.id_referencia,
                subject: "",
                body: "",
                adjunto_nombre: "",
                formato: "plain/text"
            };

            this.plantillaSel = newRec;
            this.editDialog = true;
		},
		edit(registro) {			
            this.plantillaSel = registro;
            this.editDialog = true;
		},
		toggle(event)
		{
			var ol = this.$refs.comodines;
			ol.toggle(event);
		},
		confirmDelete(registro) {
			this.plantillaSel = registro;
			this.deleteDialog = true;
		}, 
		validateEmail(email){
			email = this.perfil.remitente_email;
			var re = /\S+@\S+\.\S+/;
			var valid =  re.test(email);
			console.log(`Email valido: ${valid}`);
		},		
        async guardarRec() {            
			this.loading = true;
            try {        
                var data = await GrlService.registrarPlantillalNotificacion(this.$store.state.auth.currentAppCtx, this.plantillaSel);

                if(this.plantillaSel.id_notificacion_plantilla == '')
                {
                    this.plantillaSel.id_notificacion_plantilla = data.id_notificacion_plantilla;
                    this.plantillas.push(JSON.parse(JSON.stringify(this.plantillaSel)));
                } else {
					for (var i =0; i < this.plantillas.length; i++) {
						if (this.plantillas[i].id_notificacion_plantilla == this.plantillaSel.id_notificacion_plantilla) {
							this.plantillas[i] = JSON.parse(JSON.stringify(this.plantillaSel));							
							break;
						}
					}	                    
                }
                this.loading = false;	
                this.editDialog = false;
            } catch (err) {
				this.loading = false;
				this.$toast.add({severity:'error', summary: 'Error', detail: err, life: 3000})		                
            }            
        },
        async deleteRec() {            
			this.loading = true;
            try {        
                this.deleteDialog = false;

                var data = await GrlService.removerPlantillalNotificacion(this.$store.state.auth.currentAppCtx, this.plantillaSel);

                this.plantillas = this.plantillas.filter(val => val.id_notificacion_plantilla !== this.plantillaSel.id_notificacion_plantilla);
				this.plantillaSel = {};
                
                this.loading = false;	
            } catch (err) {
				this.loading = false;
				this.$toast.add({severity:'error', summary: 'Error', detail: err, life: 3000})		                
            }            
        },        
        expandAll() {
            this.expandedRows = this.ResumenData.filter(p => p.id);
        },
        collapseAll() {
            this.expandedRows = null;
        },
		formatCurrency(value) {
			if(value)
				return value.toLocaleString('en-US', {style: 'currency', currency: this.id_moneda});
			return;
		},
		formatDocument(Docto)
		{
			return Docto.toString().padStart(6, '0');
		},
	}
}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-corriente {
		background: #C8E6C9;
		color: #256029;
	}
	&.status-a_15 {
		background: #FFCDD2;
		color: #f7df0a;
	}

	&.status-a_30 {
		background: #FEEDAF;
		color: #f18520;
	}

	&.status-mas_30{
		background: #FFCDD2;
		color: #C63737;
	}
}
/* Responsive */
.p-datatable-customers .p-datatable-tbody > tr > td .p-column-title {
	display: none;
}

@media screen and (max-width: 960px) {
	::v-deep(.p-datatable) {
		&.p-datatable-customers {
			.p-datatable-thead > tr > th,
			.p-datatable-tfoot > tr > td {
				display: none !important;
			}

			.p-datatable-tbody > tr {
				border-bottom: 1px solid var(--surface-d);

				> td {
					text-align: left;
					display: block;
					border: 0 none !important;
					width: 100% !important;
					float: left;
					clear: left;
					border: 0 none;

					&:last-child {
						text-align: center;
					}

					.p-column-title {
						padding: .4rem;
						min-width: 30%;
						display: inline-block;
						margin: -.4rem 1rem -.4rem -.4rem;
						font-weight: bold;
					}

					.p-progressbar {
						margin-top: .5rem;
						display: inline-block;
						width: 60%;
					}

					.p-rating {
						display: inline-block;
					}
				}
			}

			.p-datatable-tbody > tr.p-rowgroup-footer{
				display: flex;
			}
		}
	}
}

::v-deep(.p-toolbar) {
	flex-wrap: wrap;
	.p-button {
		margin-bottom: .25rem;
	}
}

::v-deep(.row-anulada) {
    background-color: rgba(0,0,0,.15) !important;
}

.vencido {
    font-weight: 700;
    color: red;
}

.corriente {
	font-weight: 700;
	color: green;
}

.a_15 {
	font-weight: 700;
	color: #f7df0a;
}

.a_30 {
	font-weight: 700;
	color: #f18520;
}

.mas_30{
	font-weight: 700;
	color: red;
}

</style>    

